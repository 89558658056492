<template>
  <div>
    <vue-element-loading :active="loader" spinner="bar-fade-scale" />
    <b-card>
      <template #header>
        <h3>{{ $t(`configurationAdmin.generalTitle`) }}</h3>
      </template>
      <template #default>
        <b-row>
          <b-col cols="7" md="3">
            <b-form-checkbox v-model="payload.isMaintenance" name="check-button" switch>
              <span>{{ $t(`configurationAdmin.form.maintenance`) }}</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="4" md="3">
            <b :class="payload.isMaintenance ? `text-success` : `text-danger`">{{
              payload.isMaintenance ? $t(`general.true`) : $t(`general.false`)
            }}</b>
          </b-col>
          <b-col cols="4" md="3">
            <b-form-group label="Wifi">
              <b-form-input v-model="payload.wifiName" @input="payload.wifiName = $event !== '' ? $event : null" trim />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template #footer>
        <div class="d-flex">
          <submit-button class="ml-auto" @click="save"></submit-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import ClientService from "@api/client";
export default {
  name: `ConfigurationAdmin`,
  data() {
    return {
      payload: {
        telegramNotif: false,
        wifiName: null,
      },
      loader: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async save() {
      this.loader = true;
      try {
        await ClientService.put("config", this.payload);
        this.showToast(`success`, this.$t(`configurationAdmin.addSuccess`));
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async loadData() {
      this.loader = true;
      try {
        this.payload = await ClientService.get(`config`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
