export default [
  {
    path: "/nawala-list",
    name: "nawala-list",
    component: () => import("@/views/nawala/List.vue"),
    meta: {
      action: `read`,
      resource: `nawala`
    }
  },
  {
    path: "/nawala-config",
    name: "nawala-config",
    component: () => import("@/views/nawala/Configuration.vue"),
    meta: {
      action: `update`,
      resource: `nawala`
    }
  }
];
