export const SET_LOCALE = (locale) => {
  localStorage.setItem("userLocale", locale);
};

export const GET_LOCALE = () => {
  let locale = localStorage.getItem("userLocale");
  if (!locale) {
    locale = "en";
    SET_LOCALE("en");
  }
  return locale;
};
