<template>
  <div class="component-wrapper">
    <h1 class="text-center">
      <span>Hi, </span>
      <i>{{ pascalCase(user.name) }}</i>
    </h1>
    <div v-if="isInRoles([`client`])" title="Client Dashboard">
      <dashboard-client></dashboard-client>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DashboardClient from "./components/ClientDashboard.vue";
export default {
  name: `MainDashboard`,
  components: { DashboardClient },
  data() {
    return {};
  },
  computed: {
    ...mapState("oidc", ["user"]),
  },
  async mounted() {},
  methods: {},
};
</script>
