<template>
  <div>
    <b-card class="main-content" body-class="d-flex flex-column">
      <vue-element-loading :active="loader" spinner="bar-fade-scale" />
      <template #header>
        <div>
          <b-input-group>
            <b-input-group-prepend is-text><b-icon icon="search"></b-icon></b-input-group-prepend>
            <b-form-input v-model="search" :placeholder="$t('general.filter')" debounce="500"></b-form-input>
          </b-input-group>
        </div>

        <b-button class="ml-auto" variant="outline-success" @click.stop="showDetail()">
          {{ $t("client.createButton") }}
        </b-button>
      </template>

      <b-table
        ref="clientTable"
        class="stacked-table"
        :items="table.items"
        :fields="fieldTable"
        :filter="search"
        :filter-included-fields="filterFields"
        :current-page="table.paging.pageNo"
        :per-page="table.paging.pageSize"
        striped
        hover
        show-empty
        stacked="sm"
      >
        <template #cell(username)="data">
          {{ pascalCase(data.item.username) }}
        </template>
        <template #cell(contact)="data">
          <!-- ONLY SHOWS WHEN Whatsapp Data available -->
          <div v-if="data.item.whatsapp">
            <span>Whatsapp : </span>
            <span
              class="text-primary"
              v-clipboard:copy="data.item.whatsapp"
              v-clipboard:success="successCopy"
              v-tippy
              content="Copy to clipboard"
            >
              <span>{{ data.item.whatsapp }}</span>
            </span>
          </div>

          <!-- ONLY SHOWS WHEN Whatsapp Data available -->
          <div v-if="data.item.telegram">
            <span>Telegram : </span>
            <span
              class="text-primary"
              v-clipboard:copy="`@` + data.item.telegram"
              v-clipboard:success="successCopy"
              v-tippy
              content="Copy to clipboard"
            >
              <span>{{ data.item.telegram }}</span>
            </span>
          </div>
        </template>
        <template #cell(brandDefense)="data">
          5 Brands
          <b-button
            size="sm"
            variant="outline-warning"
            class="ml-1"
            v-b-tooltip.hover
            :title="$t(`client.showButton`)"
            @click.stop="showList(data.item)"
          >
            <feather-icon icon="EyeIcon"></feather-icon>
          </b-button>
        </template>

        <template #cell(nwChecker)="data">
          {{ data.item.slotCount }} Slots
          <b-button
            size="sm"
            variant="outline-warning"
            class="ml-1"
            v-b-tooltip.hover
            :title="$t(`client.showButton`)"
            @click.stop="showList(data.item)"
          >
            <feather-icon icon="EyeIcon"></feather-icon>
          </b-button>
        </template>

        <template #cell(action)="data">
          <b-button
            size="sm"
            variant="outline-primary"
            v-b-tooltip.hover
            :title="$t(`client.editButton`)"
            @click.stop="showDetail(data.item)"
          >
            <feather-icon icon="EditIcon"></feather-icon>
          </b-button>

          <b-button
            v-if="data.item.defaultPassword"
            size="sm"
            variant="outline-dark"
            class="ml-1"
            v-b-tooltip.hover
            :title="$t(`client.copyButton`)"
            @click.stop="copyCredential(data.item)"
          >
            <feather-icon icon="CopyIcon"></feather-icon>
          </b-button>
          <b-button
            size="sm"
            variant="outline-info"
            class="ml-1"
            v-b-tooltip.hover
            :title="$t(`client.resetPasswordButton`)"
            @click.stop="resetPassword(data.item)"
          >
            <feather-icon icon="ShieldOffIcon"></feather-icon>
          </b-button>
        </template>
      </b-table>

      <div class="d-flex mt-auto align-items-center">
        <page-info :page-no="table.paging.pageNo" :page-size="table.paging.pageSize" :total="table.items.length" />
        <b-pagination
          v-model="table.paging.pageNo"
          :totalRows="table.items.length"
          :per-page="table.paging.pageSize"
          align="center"
          class="ml-auto"
        ></b-pagination>
      </div>
    </b-card>

    <b-modal
      v-model="modal.detail"
      :title="
        selectedRow
          ? `${$t('client.modal.detail.editTitle')} ${selectedRow.username}`
          : $t(`client.modal.detail.newTitle`)
      "
      no-close-on-backdrop
      lazy
      centered
      hide-footer
      @hidden="onDetailClose"
    >
      <client-detail :rec="selectedRow" @onSaveSuccess="onDetailSuccess" @onClose="modal.detail = false" />
    </b-modal>

    <b-modal
      v-if="selectedRow"
      v-model="modal.nawalaList"
      :title="`${selectedRow.username} / ${selectedRow.description || '-'}`"
      size="xl"
      no-close-on-backdrop
      lazy
      centered
      hide-footer
      @hide="onDetailClose"
    >
      <nawala-list :rec="selectedRow" @updateSlot="onNawalaListUpdateSlot" />
    </b-modal>
  </div>
</template>

<script>
import ClientDetail from "./Detail.vue"
import NawalaList from "@/views/nawala/List.vue"
import ClientService from "@api/client"
import AuthService from "@api/auth"
import { v4 as uuidv4 } from "uuid"

export default {
  name: `ClientList`,
  components: { NawalaList, ClientDetail },
  data() {
    return {
      search: null,
      selectedRow: null,
      table: {
        items: [],

        paging: {
          pageNo: 1,
          pageSize: 10,
        },
      },
      modal: { nawalaList: false, detail: false },
      loader: false,
    }
  },
  computed: {
    fieldTable() {
      let fields = [
        {
          key: `username`,
          label: `${this.$i18n.t("client.table.username")}`,
          sortable: true,
          filterable: true,
        },
        {
          key: `description`,
          label: `${this.$i18n.t("client.table.description")}`,
          sortable: true,
          filterable: true,
        },
        {
          key: `contact`,
          label: `${this.$i18n.t("client.table.contact")}`,
          sortable: true,
          class: `text-right`,
        },
        {
          key: `nwChecker`,
          label: `${this.$i18n.t("client.table.nwChecker")}`,
          sortable: true,
          class: `text-right`,
        },
        {
          key: "action",
          label: ``,
          class: `text-right`,
          thClass: `action-column`,
          tdClass: `action-column`,
        },
      ]
      return fields
    },
    filterFields() {
      let retVal = this.fieldTable.filter(f => f.filterable).map(x => x.key)
      return retVal
    },
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.loader = true
      try {
        this.table.items = await ClientService.get(`useraccount`)
      } catch (error) {
        this.showToast(`error`, error)
      } finally {
        this.loader = false
      }
    },
    showDetail(row) {
      if (row) this.selectedRow = { ...row }
      this.modal.detail = true
    },
    showList(row) {
      this.selectedRow = { ...row }
      this.modal.nawalaList = true
    },
    async onDetailSuccess(rec, mode) {
      // UPDATING COLLECTION INSTEAD OF RELOADING
      if (mode == `create`) this.table.items.unshift(rec)
      else this.updateCollection(this.table.items, `username`, rec)
      this.showToast(`success`, `Client ${rec.description} Saved`)
      this.modal.detail = false
      this.$refs.clientTable.refresh()
    },
    async onDetailClose() {
      this.selectedRow = null
    },
    onNawalaListUpdateSlot(rec) {
      this.updateCollection(this.table.items, `username`, rec)

      this.$refs.clientTable.refresh()
    },
    async copyCredential(row) {
      this.loader = true
      const text = `
              LOGIN URL: ${window.location.origin}
              Username: ${row.username}
              Password: ${row.defaultPassword}

              Please change your password when you have logged in.
                `
      try {
        await ClientService.put("useraccount", {
          ...row,
          defaultPassword: null,
        })
      } catch (error) {
        this.showToast(`error`, error)
      } finally {
        this.loader = false
        row.defaultPassword = null
        this.updateCollection(this.table.items, `username`, row)
        this.$copyText(text).then(() => this.showToast("success", this.$t("general.copySuccess")))

        this.$refs.clientTable.refresh()
      }
    },
    async resetPassword(row) {
      this.loader = true
      let password = uuidv4()
      try {
        let result = await AuthService.put(`account/resetPassword`, {
          username: row.username,
          newPassword: password,
        })

        await ClientService.put("useraccount", {
          ...row,
          defaultPassword: password,
        })
        this.showToast(`success`, `Client ${row.username} Reset Password`)
      } catch (error) {
        this.showToast(`error`, error)
      } finally {
        this.loader = false
      }
      row.defaultPassword = password
      this.updateCollection(this.table.items, `username`, row)
      this.$refs.clientTable.refresh()
    },
    onCopy() {
      this.showToast(`success`, `Copy Success`)
    },
  },
}
</script>
