export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/dashboard/Main.vue"),
    meta: {
      action: `read`,
      resource: `dashboard`
    }
  }
];
