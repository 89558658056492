export default [
  {
    path: "/client",
    name: "client-list",
    component: () => import("@/views/client/List.vue"),
    meta: {
      action: "read",
      resource: "client"
    }
  }
];
