<template>
  <div>
    <b-table :items="statusList" :fields="table.fields" small striped hover>
      <template #cell(status)="data">
        <div class="text-center">
          <b-badge
            :variant="
              data.item.status === 'blocked' ? 'danger' : data.item.status === 'pending' ? 'warning' : 'success'
            "
          >
            {{ data.item.status }}
          </b-badge>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: `NawalaStatusDetail`,
  props: {
    statusList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      table: {
        fields: [
          {
            key: `provider`,
            label: this.$i18n.t("nawala.modal.status.provider"),
          },
          {
            key: `lastCheck`,
            label: this.$i18n.t("nawala.modal.status.lastCheck"),
          },
          { key: `status`, label: this.$i18n.t("nawala.modal.status.status"), class: `text-center` },
        ],
      },
    }
  },
}
</script>
