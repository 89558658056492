export default [
  {
    path: "/auth/callback",
    name: "auth-callback",
    component: () => import("@/views/auth/OidcCallback.vue"),
    meta: {
      layout: "full"
    }
  },
  {
    path: "/auth/signout",
    name: "auth-signout",
    component: () => import("@/views/auth/SignOut.vue"),
    meta: {
      layout: "full",
      requiresAuth: false
    }
  }
];
