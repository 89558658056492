<template>
  <div>
    <!-- TODO: APPLY LOGIC TO FILTER STATUS-->
    <b-card class="main-content" body-class="d-flex flex-column">
      <vue-element-loading :active="loader" spinner="bar-fade-scale" />
      <template #header>
        <b-button
          v-if="$can('create', 'nawala')"
          variant="outline-success"
          :class="!($can('remove', 'nawala') && table.selectedRows.length > 0) ? `mr-auto` : ``"
          @click.stop="showModalInsertNumber"
        >
          {{ $t("nawala.createButton") }}
        </b-button>
        <b-button
          v-if="$can('remove', 'nawala') && table.selectedRows.length > 0"
          variant="outline-danger"
          class="ml-1 mr-auto"
          @click.stop="removeBatch"
        >
          {{ $t("nawala.removeBatchButton") }}
        </b-button>
        <div class="ml-auto">
          <span>{{ $t("nawala.totalSlot") }} : </span>
          <b class="text-primary h3">{{ table.items.length <= 0 ? `-` : table.items.length }}</b>
        </div>
      </template>
      <b-table
        ref="nawalaTable"
        :items="filteredList"
        :fields="computedFields"
        :current-page="table.paging.pageNo"
        :per-page="table.paging.pageSize"
        striped
        hover
        show-empty
        stacked="sm"
      >
        <template #top-row>
          <td><b-input v-model="filter.description" placeholder="Filter Description" debounce="500"></b-input></td>
          <td><b-input v-model="filter.domain" placeholder="Filter Domain" debounce="500"></b-input></td>
          <td>
            <b-form-select v-model="filter.status" :options="statusList"></b-form-select>
          </td>
          <td class="d-none d-md-table-cell"></td>
        </template>
        <template #head(select)>
          <b-form-checkbox v-model="table.isSelectAll" />
        </template>
        <template #cell(select)="data">
          <b-form-checkbox v-model="table.selectedRows" :value="data.item.code"></b-form-checkbox>
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.item.status === 'ok'" variant="success">OK</b-badge>
          <b-badge v-else-if="data.item.status === 'blocked'" variant="danger">Blocked</b-badge>
          <b-badge v-else-if="data.item.status === 'pending'" variant="warning">Pending</b-badge>
          <b-button
            class="ml-1"
            size="sm"
            variant="primary"
            v-b-tooltip.hover
            :title="$t('nawala.statusButton')"
            @click.stop="showStatus(data.item)"
          >
            <feather-icon icon="ActivityIcon"></feather-icon>
          </b-button>
        </template>
        <template #cell(action)="data">
          <b-button
            v-if="$can('update', 'nawala')"
            size="sm"
            variant="info"
            v-b-tooltip.hover
            :title="$t('nawala.editButton')"
            @click.stop="modify(data.item)"
          >
            <feather-icon icon="EditIcon"></feather-icon>
          </b-button>
          <b-button
            v-if="$can('remove', 'nawala')"
            size="sm"
            variant="danger"
            class="ml-1"
            v-b-tooltip.hover
            :title="$t(`nawala.removeButton`)"
            @click.stop="remove(data.item)"
          >
            <feather-icon icon="TrashIcon"></feather-icon>
          </b-button>
        </template>
      </b-table>

      <div class="mt-auto pt-1">
        <b-pagination
          v-model="table.paging.pageNo"
          :totalRows="table.items.length"
          :per-page="table.paging.pageSize"
          align="center"
        ></b-pagination>
      </div>
    </b-card>

    <b-modal
      v-model="modal.detail"
      :title="selectedRow ? $t('nawala.modal.detail.editTitle') : $t('nawala.modal.detail.newTitle')"
      lazy
      centered
      no-close-on-backdrop
      hide-footer
      @hide="onDetailClose"
    >
      <detail v-if="selectedRow" :rec="selectedRow" @onSaveSuccess="onDetailSuccess" @onClose="modal.detail = false" />
    </b-modal>

    <b-modal
      v-if="selectedRow"
      v-model="modal.status"
      :title="`${selectedRow.description} | ${selectedRow.domain}`"
      lazy
      centered
      hide-footer
      @hide="onDetailClose"
    >
      <status-detail v-if="selectedRow" :statusList="selectedRow.statusList" @onClose="modal.status = false" />
    </b-modal>

    <b-modal
      v-model="modal.addSlot"
      :title="$t('nawala.modal.newSlot.title')"
      lazy
      centered
      hide-footer
      @hide="onDetailClose"
    >
      <insert-number :rec="rec" @onSaveSuccess="onAddSlotSuccess" @onClose="modal.addSlot = false" />
    </b-modal>
  </div>
</template>

<script>
import Detail from "./Detail.vue";
import StatusDetail from "./StatusDetail.vue";
import InsertNumber from "./InsertNumber.vue";
import ClientService from "@api/client";
import { mapState } from "vuex";

export default {
  name: `NawalaList`,
  components: { Detail, StatusDetail, InsertNumber },
  props: {
    rec: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      search: null,
      selectedRow: null,
      table: {
        items: [],
        paging: {
          pageNo: 1,
          pageSize: 10,
        },
        selectedRows: [],
        isSelectAll: false,
      },
      statusList: [
        { value: null, text: "All Status" },
        { value: "blocked", text: "Blocked" },
        { value: "ok", text: "Ok" },
      ],
      filter: {
        description: null,
        domain: null,
        status: null,
      },
      modal: { detail: false, status: false, addSlot: false },
      loader: false,
    };
  },
  computed: {
    tableFields() {
      let fields = [
        { key: "select", label: "", onlyAdmin: true, thStyle: `width: 40px` },
        {
          key: "description",
          label: `${this.$i18n.t("nawala.table.description")}`,
          filterable: true,
        },
        {
          key: "domain",
          label: `${this.$i18n.t("nawala.table.url")}`,
          filterable: true,
        },
        {
          key: "status",
          label: `${this.$i18n.t("nawala.table.status")}`,
          class: `text-right`,
        },
        {
          key: "action",
          label: "",
          class: `text-right`,
          thClass: `action-column`,
          tdClass: `action-column`,
        },
      ];
      return fields;
    },
    filteredList() {
      let retVal = [...this.table.items];
      if (this.filter.description)
        retVal = this.table.items.filter(item =>
          item.description.toLowerCase().includes(this.filter.description.toLowerCase()),
        );
      if (this.filter.domain)
        retVal = this.table.items.filter(item => item.domain.toLowerCase().includes(this.filter.domain.toLowerCase()));
      return retVal;
    },
    computedFields() {
      let userRole = this.user?.role.toLowerCase();
      return userRole === `admin` ? this.tableFields : this.tableFields.filter(f => !f.onlyAdmin);
    },
    ...mapState("oidc", ["user"]),
  },
  watch: {
    "table.isSelectAll": function (val) {
      this.checkAll(val);
    },
  },
  async mounted() {
    await this.checkLoaded();
  },
  methods: {
    async checkLoaded() {
      if (this.user.role.toLowerCase() == "client") {
        await this.loadData(this.user.name);
      } else if (this.rec) {
        await this.loadData(this.rec.username);
      }
    },
    async loadData(username) {
      this.loader = true;
      try {
        this.table.items = await ClientService.get(`slot/${username}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    modify(row) {
      this.selectedRow = { ...row };
      this.modal.detail = true;
    },
    async showStatus(row) {
      this.loader = true;
      this.selectedRow = { ...row };
      try {
        this.selectedRow.statusList = await ClientService.get(`slotcheckresult/${this.selectedRow.code}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
      this.modal.status = true;
    },
    showModalInsertNumber() {
      this.modal.addSlot = true;
    },
    onDetailClose() {
      this.selectedRow = null;
    },
    async remove(row) {
      let confirmed = await this.showConfirm(this.$t(`nawala.removeConfirm`));
      if (!confirmed) return;
      this.loader = true;
      let index = this.table.items.indexOf(row);
      if (index > -1) {
        try {
          await ClientService.delete(`slot`, { code: row.code });
          this.table.items.splice(index, 1);
        } catch (error) {
          this.showToast(`error`, error);
        } finally {
          this.loader = false;
        }
      }
      this.showToast(`success`, this.$t(`nawala.removeSuccess`));
    },
    async removeBatch() {
      let confirmed = await this.showConfirm(this.$t(`nawala.removeConfirm`));
      if (!confirmed) return;
      this.loader = true;
      try {
        await ClientService.post(`slot/removeBatch`, this.table.selectedRows);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }

      this.table.selectedRows.forEach(item => {
        //find same code for each row deleted
        const index = this.table.items.findIndex(tableItem => tableItem.code === item);
        if (index > -1) {
          this.table.items.splice(index, 1);
        }
      });
      this.showToast(`success`, this.$t(`nawala.removeSuccess`));
    },
    async onDetailSuccess(rec, mode) {
      // UPDATING COLLECTION INSTEAD OF RELOADING
      if (mode == `create`) this.filteredList.unshift(rec);
      else this.updateCollection(this.filteredList, `code`, rec);
      this.showToast(`success`, `Slot ${rec.description} Saved`);
      this.modal.detail = false;
      this.$refs.nawalaTable.refresh();
    },
    async onAddSlotSuccess(rec, mode) {
      await this.checkLoaded();
      this.modal.addSlot = false;
      this.rec.slotCount = this.table.items.length;
      this.$emit("updateSlot", this.rec);
    },
    checkAll(val) {
      if (val) {
        this.table.selectedRows = this.table.items.map(x => x.code);
      } else {
        this.table.selectedRows = [];
      }
    },
  },
};
</script>
