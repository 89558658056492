import Vue from "vue";
import axios from "axios";
import store from "@/store";

class ApiBaseClass {
  constructor(serviceKey) {
    this.serviceKey = serviceKey;
    this.http = null;
  }

  ensureInitialized() {
    if (!this.http) {
      const config = Vue.prototype.$config.apiConfig[this.serviceKey];

      const baseURL = config.baseUrl;

      if (!baseURL) {
        throw new Error(`Base URL for ${this.serviceKey} is not defined in configuration.`);
      }

      this.http = axios.create({
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json"
        }
      });

      this.setupInterceptors();
    }
  }

  setupInterceptors() {
    this.http.interceptors.request.use(
      (config) => this.request(config),
      (error) => this.errorRequest(error)
    );

    this.http.interceptors.response.use(
      (response) => this.successResponse(response),
      (error) => this.errorResponse(error)
    );
  }

  //INTERCEPTORS
  request(config) {
    const token = store.getters["oidc/oidcAccessToken"];
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  }

  errorRequest(error) {
    return Promise.reject(error);
  }

  successResponse(response) {
    if (response.config.responseType === `blob`) {
      return Promise.resolve(response);
    } else if (response.data.status === "Success") {
      return Promise.resolve(response.data.result);
    } else {
      return Promise.reject(response.data.message);
    }
    return resp;
  }
  errorResponse(error) {
    if (!error.response) return Promise.reject("Network Error.");
    return Promise.reject(error);
  }
  //INTERCEPTORS

  //METHODS

  get(url, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.get(url);
  }

  post(url, params, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.post(url, params);
  }

  put(url, params, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.put(url, params);
  }

  delete(url, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.delete(url);
  }

  patch(url, data, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.patch(url, data);
  }

  download(url) {
    this.ensureInitialized();
    return this.http.get(url, {responseType: "blob"});
  }

  downloadPost(url, payload) {
    this.ensureInitialized();
    return this.http.post(url, payload, {responseType: "blob"});
  }

  upload(url, formData) {
    this.ensureInitialized();
    var config = {headers: {"Content-Type": "multipart/form-data"}};
    return this.http.post(url, formData, config);
  }

  buildQuery(qObject) {
    let retVal = "";
    let keys = Object.keys(qObject);
    keys.forEach((x, i) => {
      if (i === 0) retVal += `?${x}=${qObject[x]}`;
      else retVal += `&${x}=${qObject[x]}`;
    });
    return retVal;
  }
}

export default ApiBaseClass;
