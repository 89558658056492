<template>
  <div>
    <vue-element-loading :active="loader" spinner="bar-fade-scale" />
    <b-card title="Nawala Status">
      <b-list-group>
        <b-list-group-item variant="danger">Blocked</b-list-group-item>
        <b-list-group-item v-for="(item, index) in listItemsBlocked" :key="index">
          <b-row>
            <b-col cols="3"> {{ item.description }}</b-col>

            <b-col cols="3"> {{ item.domain }}</b-col>

            <b-col cols="3"> {{ item.providerList }}</b-col>

            <b-col cols="3" class="d-flex justify-content-end">
              <small class="text-danger">{{ item.time }} mins ago</small></b-col
            >
          </b-row>
        </b-list-group-item>
      </b-list-group>

      <b-list-group class="mt-1">
        <b-list-group-item variant="success">Safe</b-list-group-item>
        <b-list-group-item v-for="(item, index) in listItemsOk" :key="index">
          <b-row>
            <b-col cols="3"> {{ item.description }}</b-col>

            <b-col cols="3"> {{ item.domain }}</b-col>

            <b-col cols="3"> {{ item.providerList }}</b-col>

            <b-col cols="3" class="d-flex justify-content-end">
              <small class="text-success">{{ item.time }} mins ago</small></b-col
            >
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>
<script>
import ClientService from "@api/client";
import { mapState } from "vuex";

export default {
  name: `ClientDashboard`,
  data() {
    return {
      listItemsBlocked: [],
      listItemsOk: [],
      loader: false,
    };
  },
  computed: {
    ...mapState("oidc", ["user"]),
  },
  async mounted() {
    if (this.user.role.toLowerCase() == "client") {
      this.loader = true;
      await this.loadData(this.user.name, "blocked");
      await this.loadData(this.user.name, "ok");
      this.loader = false;
    }
  },
  methods: {
    async loadData(username, status) {
      try {
        if (status == "blocked") {
          this.listItemsBlocked = await ClientService.get(`slot/dashboard`, { username: username, status: "blocked" });
        } else {
          this.listItemsOk = await ClientService.get(`slot/dashboard`, { username: username, status: "ok" });
        }
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
      }
    },
  },
};
</script>