<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader" spinner="bar-fade-scale" />
    <b-form-group :label="$t('client.modal.detail.username')">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="UserIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          v-model="payload.username"
          :placeholder="`Enter ${$t('client.modal.detail.username')}`"
          :readonly="!!rec"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group :label="$t('client.modal.detail.description')">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="InfoIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          v-model="payload.description"
          :placeholder="`Enter ${$t('client.modal.detail.description')}`"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Whatsapp">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="PhoneIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          v-model="payload.whatsapp"
          placeholder="Enter Whatsapp"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Telegram">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <feather-icon icon="NavigationIcon" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          v-model="payload.telegram"
          placeholder="Enter Telegram"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group v-if="!rec" :label="$t('client.modal.detail.slotCount')">
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="primary" @click.stop="decrement"
            ><feather-icon icon="MinusIcon"
          /></b-button>
        </b-input-group-prepend>
        <b-form-input v-model="payload.slotCount" type="number"></b-form-input>
        <b-input-group-append>
          <b-button variant="primary" @click.stop="increment"
            ><feather-icon icon="PlusIcon"
          /></b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <hr />

    <div class="d-flex">
      <submit-button class="ml-auto"></submit-button>
      <cancel-button class="ml-1" @click="closeModal"></cancel-button>
    </div>
  </b-form>
</template>

<script>
import ClientService from "@api/client";
import AuthService from "@api/auth";

import { v4 as uuidv4 } from "uuid";
export default {
  name: `ClientDetail`,
  props: {
    rec: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      payload: {
        description: null,
        username: null,
        telegram: null,
        whatsapp: null,
        defaultPassword: uuidv4(),
        slotCount: 0,
      },
      loader: false,
    };
  },
  mounted() {
    if (this.rec) {
      this.payload = this.rec;
    }
  },
  methods: {
    async save() {
      this.loader = true;
      try {
        let result;
        let mode = this.rec ? "update" : "create";

        if (mode == `update`) {
          result = await ClientService.put("useraccount", this.payload);
        } else {
          await this.registerAccount();
          result = await ClientService.post("useraccount", this.payload);
        }
        this.$emit("onSaveSuccess", result, mode);
        this.clearObject(this.payload);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async registerAccount() {
      try {
        let result = await AuthService.post(`account/registerUser`, {
          username: this.payload.username,
          password: this.payload.defaultPassword,
          role: "client",
        });
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
    increment() {
      this.payload.slotCount++;
    },
    decrement() {
      if (this.payload.slotCount > 0) {
        this.payload.slotCount--;
      }
    },
  },
};
</script>
