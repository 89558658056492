<template>
  <b-form @submit.prevent="save">
    <b-row>
      <b-col>
        <b-form-group label="Username">
          <b-form-input
            v-model="form.username"
            :readonly="!!rec"
            @input="form.username = $event !== '' ? $event : null"
            trim
          />
        </b-form-group>
        <b-form-group label="Fullname">
          <b-form-input
            v-model="form.fullname"
            @input="form.fullname = $event !== '' ? $event : null"
            trim
          />
        </b-form-group>
        <b-form-group label="Email">
          <b-form-input
            v-model="form.email"
            @input="form.email = $event !== '' ? $event : null"
            trim
          />
        </b-form-group>
        <b-form-group label="WA">
          <b-form-input
            v-model="form.whatsapp"
            @input="form.whatsapp = $event !== '' ? $event : null"
            trim
          />
        </b-form-group>
        <b-form-group label="Phone">
          <b-form-input
            v-model="form.phone"
            @input="form.phone = $event !== '' ? $event : null"
            trim
          />
        </b-form-group>
        <b-form-group label="telegram">
          <b-form-input
            v-model="form.telegram"
            @input="form.telegram = $event !== '' ? $event : null"
            trim
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="text-right">
      <b-button
        type="submit"
        :disabled="loader.form"
        variant="success"
        class="ml-auto mr-1"
      >
        <b-spinner v-if="loader.form" class="mr-1" variant="primary" small />
        Save
      </b-button>
      <b-button :disabled="loader.form" variant="info" @click.stop="cancel">
        Cancel
      </b-button>
    </div>
  </b-form>
</template>
  
  <script>
import Api from "@api/client";

export default {
  name: `MemberDetail`,
  props: {
    rec: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        username: null,
        phone: null,
        fullname: null,
        whatsapp: null,
        email: null,
        telegram: null,
      },
      typeList: [],
      loader: false,
    };
  },
  mounted() {
    if (this.rec) {
      this.form.code = this.rec.code;
      this.loadMember();
    }
  },

  methods: {
    async loadMember() {
      try {
        this.form = await Api.get(`member/${this.rec.username}`);
      } catch (error) {
        showToast(`error`, error);
      }
    },

    async save() {
      this.loader = true;
      try {
        let result;
        let mode = this.rec ? "update" : "create";

        if (mode == `update`) {
          result = await Api.put("member", this.form);
        } else {
          result = await Api.post("member", this.form);
        }
        this.$emit("onSaveSuccess", result, mode);
        this.clearObject(this.form);
      } catch (error) {
        showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    cancel() {
      this.$emit("onCancel");
    },
  },
};
</script>
  