export default [
    {
      path: "/config",
      name: "config",
      component: () => import("@/views/config/Configuration.vue"),
      meta: {
        action: "manage",
        resource: "config"
      }
    }
  ];
  