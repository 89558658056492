export default [
  {
    header: "Nawala Checker",
    icon: "RssIcon",
    children: [
      {
        title: "Url Control",
        route: "nawala-list",
        icon: "SlidersIcon",
        action: "read",
        resource: "nawala"
      },
      {
        title: "Configuration",
        route: "nawala-config",
        icon: "ToolIcon",
        action: "update",
        resource: "nawala"
      }
    ]
  }
];
