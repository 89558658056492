import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import {vuexOidcCreateRouterMiddleware} from "vuex-oidc";

// Routes
import general from "./routes/general";
import auth from "./routes/auth";
import nawala from "./routes/nawala";
import client from "./routes/client";
import member from "./routes/member";
import config from "./routes/config";

Vue.use(VueRouter);

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  routes: [
    ...general,
    ...auth,
    ...client,
    ...nawala,
    ...member,
    ...config,
    {
      path: "*",
      redirect: "error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidc"));
