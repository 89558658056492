<template>
  <b-button type="submit" variant="success" @click.stop="onClick">
    <feather-icon icon="SaveIcon"></feather-icon>
    {{ $t("general.save") }}
  </b-button>
</template>

<script>
export default {
  name: `SubmitButton`,
  methods: {
    onClick() {
      this.$emit(`click`);
    },
  },
};
</script>
