import Vue from "vue";
import {Ability} from "@casl/ability";
import DefaultAbility from "./default";
import AdminAbility from "./admin";
import ClientAbility from "./client";

Vue.prototype.$ability = new Ability([]);

function getAbility(role) {
  switch (role) {
    case "admin":
      return [...DefaultAbility, ...AdminAbility];
    case "client":
      return [...DefaultAbility, ...ClientAbility];
    default:
      return [...DefaultAbility];
  }
}

function updateAbility(role) {
  const abilities = getAbility(role);
  Vue.prototype.$ability.update(abilities);
}

const userData = JSON.parse(localStorage.getItem("userData"));
updateAbility(userData ? userData.role : null);

export default Vue.prototype.$ability;

export {getAbility, updateAbility};
