<template>
  <div class="component-wrapper">
    <b-button
      class="ml-auto"
      size="sm"
      variant="primary"
      @click.stop="showDetail(null)"
    >
      <feather-icon icon="PlusCircleIcon"></feather-icon>
      New Member
    </b-button>
    <b-card class="table-card">
      <template #default>
        <b-table
          ref="memberTable"
          :items="table.items"
          :fields="table.fields"
          stacked="sm"
          small
          fixed
          hover
          striped
          show-empty
          :per-page="table.paging.pageSize"
          :current-page="table.paging.pageNo"
          :filter="table.filter"
        >
          <template #cell(no)="data">
            <div>{{ data.index + 1 }}</div>
          </template>
          <template #cell(username)="data">
            <div>{{ data.item.username }}</div>
            <small v-if="!data.item.isActive" class="text-danger"
              >[Inactive]</small
            >
          </template>
          <template #cell(email)="data">
            <span>{{ data.item.email }}</span>
          </template>
          <template #cell(action)="data">
            <b-button
              content="Edit"
              variant="info"
              class="ml-1"
              size="sm"
              @click.stop="showDetail(data.item)"
            >
              <feather-icon icon="EditIcon"></feather-icon>
            </b-button>
            <b-button
              :content="data.item.isActive ? `Deactivate` : `Activate`"
              :variant="data.item.isActive ? `danger` : `success`"
              class="ml-1"
              size="sm"
              @click.stop="toggleActive(data.item)"
            >
              <feather-icon
                :icon="data.item.isActive ? `Trash2Icon` : `ActivityIcon`"
              ></feather-icon>
            </b-button>
          </template>
        </b-table>
      </template>
    </b-card>
    <b-modal
      v-model="modal"
      :title="
        !table.selected
          ? `Add Member`
          : `Edit Member ${table.selected.username}`
      "
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
      lazy
      @hide="table.selected = null"
    >
      <member-detail
        :rec="table.selected"
        @onSaveSuccess="saveSuccess"
        @onCancel="cancel"
      />
    </b-modal>
  </div>
</template>
<script>
import Api from "@api/client";
import MemberDetail from "./component/MemberDetail.vue";

export default {
  name: `MemberList`,
  components: { MemberDetail },
  data() {
    return {
      table: {
        items: [],
        filter: null,
        fields: [
          { key: `No`, label: `#`, thStyle: `width: 50px` },
          { key: `username`, label: `Username`, sortable: true },
          { key: `phone`, label: `phone`, sortable: true },
          { key: `Email`, label: `Email`, sortable: true },
          { key: `action`, label: `Action`, class: `text-right` },
        ],
        paging: {
          pageNo: 1,
          pageSize: 20,
        },
        selected: null,
      },
      loader: false,
      modal: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    showDetail(rec) {
      this.table.selected = rec;
      this.modal = true;
    },
    async loadData() {
      this.loader = true;

      try {
        this.table.items = await Api.get(`member`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    async saveSuccess(rec, mode) {
      // UPDATING COLLECTION INSTEAD OF RELOADING
      if (mode == `create`) this.table.items.unshift(rec);
      else this.updateCollection(this.table.items, `code`, rec, mode);
      this.showToast(`success`, `Member ${rec.code} Saved`);
      this.modal = false;
      this.$refs.memberTable.refresh();
    },

    async toggleActive(rec) {
      let confirmed = await this.showConfirm(`Toggle Active ?`);
      if (!confirmed) return;
      this.loader = true;
      try {
        await Api.delete(`member`, { username: rec.username });
        rec.isActive = !rec.isActive;
        this.toggleSuccess(rec.isActive);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    cancel() {
      this.modal = false;
    },
  },
};
</script>