export default [
  {
    action: `manage`,
    subject: `client`
  },
  {
    action: `create`,
    subject: `nawala`
  },
  {
    action: `remove`,
    subject: `nawala`
  },
  {
    action: `manage`,
    subject: `config`
  }
];
