import {registerOidcModule} from "@/store/oidc";

export const loadConfig = async () => {
  const response = await fetch(`${process.env.BASE_URL}config.json`);
  if (!response.ok) {
    throw new Error("Failed to load configuration");
  }
  const config = await response.json();
  await registerOidcModule(config);
  return config;
};
