<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader" spinner="bar-fade-scale" />
    <b-form-group>
      <b-form-input
        v-model="payload.totalRec"
        type="number"
        :placeholder="$t('nawala.modal.newSlot.title')"
      ></b-form-input>
    </b-form-group>
    <hr />
    <div class="d-flex">
      <submit-button class="ml-auto"></submit-button>
      <cancel-button class="ml-1" @click="closeModal"></cancel-button>
    </div>
  </b-form>
</template>

<script>
import CancelButton from "@/components/CancelButton.vue"
import ClientService from "@api/client"
export default {
  components: { CancelButton },
  name: `InsertNumber`,
  props: {
    rec: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      payload: {
        totalRec: null,
      },
      loader: false,
    }
  },
  methods: {
    async loadData() {},
    async save() {
      this.loader = true
      try {
        await ClientService.post(`slot`, {
          username: this.rec.username,
          totalRec: this.payload.totalRec,
        })
        this.$emit("onSaveSuccess")
      } catch (error) {
        this.showToast(`error`, error)
      } finally {
        this.loader = false
      }
      this.showToast(`success`, this.$t(`nawala.addSuccess`))
    },
    closeModal() {
      this.$emit(`onClose`)
    },
  },
}
</script>
