<template>
  <vue-element-loading :active="true" spinner="bar-fade-scale" is-full-screen text="Signing In"></vue-element-loading>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "OidcCallback",
  methods: {
    ...mapActions(`oidc`, ["oidcSignInCallback"])
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        console.error(err);
      });
  }
};
</script>
