<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader" spinner="bar-fade-scale" />
    <b-form-group :label="$t('nawala.modal.detail.description')">
      <b-input-group>
        <template #prepend>
          <b-input-group-text><feather-icon icon="InfoIcon"></feather-icon></b-input-group-text>
        </template>
        <b-form-input
          v-model="payload.description"
          :placeholder="`Input ${$t('nawala.modal.detail.description')}`"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group :label="$t('nawala.modal.detail.url')">
      <b-input-group>
        <template #prepend>
          <b-input-group-text><feather-icon icon="LinkIcon"></feather-icon></b-input-group-text>
        </template>
        <b-form-input v-model="payload.domain" :placeholder="`Input ${$t('nawala.modal.detail.url')}`"></b-form-input>
      </b-input-group>
      <small class="text-danger">Domain without http(s). eg: facebook.com</small>
    </b-form-group>
    <hr />
    <div class="d-flex">
      <submit-button class="ml-auto"></submit-button>
      <cancel-button class="ml-1" @click="closeModal"></cancel-button>
    </div>
  </b-form>
</template>

<script>
import CancelButton from "@/components/CancelButton.vue";
import ClientService from "@api/client";
export default {
  components: { CancelButton },
  name: `NawalaDetail`,
  props: {
    rec: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      payload: {
        description: null,
        domain: null,
      },
      loader: false,
    };
  },
  async mounted() {
    if (this.rec) {
      this.payload = this.rec;
    }
  },
  methods: {
    async loadData() {},
    async save() {
      this.loader = true;
      try {
        let result;
        let mode = this.rec ? "update" : "create";

        if (mode == `update`) {
          result = await ClientService.put("slot", this.payload);
        } else {
          // result = await ClientService.post("slot", this.payload);
        }
        this.$emit("onSaveSuccess", result, mode);
        this.clearObject(this.payload);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    closeModal() {
      this.$emit(`onClose`);
    },
  },
};
</script>
