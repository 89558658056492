<template>
  <b-form @submit.prevent="save">
    <b-form-group :label="$t(`component.password.old`)">
      <b-form-input v-model="form.oldPassword" type="password" placeholder="**************" class="mb-2"></b-form-input>
    </b-form-group>

    <b-form-group :label="$t(`component.password.new`)">
      <b-form-input v-model="form.newPassword" type="password" placeholder="**************" class="mb-2"></b-form-input>
    </b-form-group>

    <b-form-group :label="$t(`component.password.confirm`)">
      <b-form-input
        v-model="form.confirmPassword"
        type="password"
        placeholder="**************"
        class="mb-2"
      ></b-form-input>
    </b-form-group>

    <hr />

    <div class="d-flex">
      <submit-button class="ml-auto"></submit-button>
    </div>
  </b-form>
</template>

<script>
import AuthService from "@api/auth";
import { mapState, mapGetters } from "vuex";
export default {
  name: `ChangePassword`,
  data() {
    return {
      form: {
        userName: null,
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    ...mapState("oidc", ["user"]),
  },
  methods: {
    async save() {
      try {
        if (this.form.newPassword === this.form.confirmPassword) {
          this.form.userName = this.user.name;
          let result = await AuthService.put("account/changepassword", this.form);
          if (result) {
            console.log(this.form.userName);
            this.$store.dispatch("app/TOGGLE_PASSWORD");
            this.showToast(`success`, this.$t(`component.password.addSuccess`));
            this.clearObject(this.form);
          }
        } else {
          this.showToast(`error`, this.$t(`component.password.passwordNotMatch`));
        }
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
