import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import SubmitButton from "@/components/SubmitButton.vue";
import CancelButton from "@/components/CancelButton.vue";
import PageInfo from "@/components/PageInfo.vue";

Vue.component(SubmitButton.name, SubmitButton);
Vue.component(CancelButton.name, CancelButton);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(PageInfo.name, PageInfo);
