<template>
  <div>
    <vue-element-loading :active="loader" spinner="bar-fade-scale" />
    <b-card>
      <template #header>
        <h3>{{ $t(`nawala.configuration.generalTitle`) }}</h3>
      </template>
      <template #default>
        <b-row>
          <b-col cols="7" md="3">
            <b-form-checkbox v-model="payload.telegramNotif" name="check-button" switch>
              <span>{{ $t(`nawala.configuration.notification.telegram`) }}</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="4" md="3">
            <b :class="payload.telegramNotif ? `text-success` : `text-danger`">{{
              payload.telegramNotif ? $t(`general.active`) : $t(`general.inactive`)
            }}</b>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="7" md="3">
            <b-form-checkbox v-model="payload.emailNotif" name="check-button" switch>
              <span>{{ $t(`nawala.configuration.notification.email`) }}</span>
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b :class="payload.emailNotif ? `text-success` : `text-danger`">{{
              payload.emailNotif ? $t(`general.active`) : $t(`general.inactive`)
            }}</b>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="7" md="3">
            <b-form-checkbox v-model="payload.repeatNotif" name="check-button" switch>
              <span>{{ $t(`nawala.configuration.notification.repeat`) }}</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="5" md="3">
            <b v-if="!payload.repeatNotif" class="text-danger">{{ $t(`general.inactive`) }}</b>
            <b-input v-else size="sm" v-model="payload.repeatMax" placeholder="Maximum"></b-input>
          </b-col>
        </b-row>
      </template>
      <template #footer>
        <div class="d-flex">
          <submit-button class="ml-auto" @click="save"></submit-button>
        </div>
      </template>
    </b-card>
    <b-card>
      <template #header>
        <h3>Telegram</h3>
        <b-icon
          class="cursor-pointer"
          icon="question-circle"
          font-scale="2"
          variant="primary"
          @click.stop="modal.telegram = true"
        ></b-icon>
      </template>
      <template #default>
        <b-row>
          <b-col cols="3">Api Key</b-col>
          <b-col cols="9" md="3">
            <b-input-group>
              <template #append>
                <b-button v-clipboard:copy="payload.nawalaApiKey" v-clipboard:success="onCopy" variant="info">
                  <feather-icon icon="CopyIcon"></feather-icon>
                </b-button>
              </template>
              <b-form-input v-model="payload.nawalaApiKey" readonly></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col offset="3">
            <b-button variant="primary" @click.stop="generateAPIKey">{{
              $t(`nawala.configuration.newApiButton`)
            }}</b-button>
          </b-col>
        </b-row>
      </template>
    </b-card>

    <b-modal v-model="modal.telegram" :title="$t(`nawala.configuration.help.title`)" hide-footer centered>
      <h5>{{ $t(`nawala.configuration.help.personal.title`) }}</h5>
      <ol>
        <li>{{ $t(`nawala.configuration.help.personal.step1`) }}</li>
        <li>{{ $t(`nawala.configuration.help.personal.step2`) }}</li>
        <li>{{ $t(`nawala.configuration.help.personal.step3`) }}</li>
      </ol>
      <h5>{{ $t(`nawala.configuration.help.group.title`) }}</h5>
      <ol>
        <li>{{ $t(`nawala.configuration.help.group.step1`) }}</li>
        <li>{{ $t(`nawala.configuration.help.group.step2`) }}</li>
        <li>{{ $t(`nawala.configuration.help.group.step3`) }}</li>
      </ol>
    </b-modal>
  </div>
</template>

<script>
import ClientService from "@api/client";
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
export default {
  name: `NawalaConfiguration`,
  data() {
    return {
      payload: {
        telegramNotif: false,
        emailNotif: false,
        repeatNotif: false,
        repeatMax: null,
        nawalaApiKey: null,
      },
      modal: { telegram: false },
      loader: false,
    };
  },
  computed: {
    ...mapState("oidc", ["user"]),
  },
  async mounted() {
    await this.loadData(this.user.name);
  },
  methods: {
    onCopy() {
      this.showToast(`copy`, this.payload.nawalaApiKey);
    },
    async save() {
      this.loader = true;
      try {
        await ClientService.put("useraccount", this.payload);
        this.showToast(`success`, this.$t(`nawala.configuration.addSuccess`));
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async loadData(username) {
      this.loader = true;
      try {
        this.payload = await ClientService.get(`useraccount/${username}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async generateAPIKey() {
      let confirmed = await this.showConfirm(`Generate New Key ?`);
      if (!confirmed) return;
      this.payload.nawalaApiKey = uuidv4();
      await this.save();
    },
  },
};
</script>
