import "./axios";
import "./bootstrap";
import "./vue-select";
import "./composition-api";
import "./sweet-alerts";
import "./toastification";
import "./clipboard";
import "./portal-vue";
import "./acl";
import "./vue-element-loading";
import "./vue-tippy";

require("@core/assets/fonts/feather/iconfont.css");
