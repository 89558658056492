export default [
  {
    action: `read`,
    subject: `nawala`
  },
  {
    action: `update`,
    subject: `nawala`
  }
];
