import Vue from "vue";
import {vuexOidcCreateStoreModule} from "vuex-oidc";
import store from "@/store";
import {updateAbility} from "@/libs/acl/ability";

export async function registerOidcModule(config) {
  store.registerModule(
    "oidc",
    vuexOidcCreateStoreModule(
      config.oidcConfig,
      {
        namespaced: true,
        isAuthenticatedBy: `access_token`,
        publicRoutePaths: ["/auth/signout"]
      },
      {
        userLoaded: (user) => onUserLoaded(user),
        userUnloaded: () => console.log(`user unloaded`),
        accessTokenExpiring: () => console.log("Access token will expire"),
        accessTokenExpired: () => console.log("Access token did expire"),
        silentRenewError: () => console.log("OIDC user is unloaded"),
        userSignedOut: () => console.log(`user sign out`),
        oidcError: (payload) => console.log(`An error occured at ${payload.context}:`, payload.error),
        automaticSilentRenewError: (payload) => console.log("Automatic silent renew failed.", payload.error)
      }
    )
  );
}

function onUserLoaded(user) {
  updateAbility(user.profile.role.toLowerCase());
}
